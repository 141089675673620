body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  /* display: flex; */
  font-family: 'Noto Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  /* fix problem with side scroll when refresh page */
  overflow-anchor: none;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.welcome-section {
  position: absolute;
  top: 10%;
  left: 10;
  z-index: 10;
}
.welcome-title {
  font-size: 1.5rem;
  color: #3b3b3b;
}

.parent {
  position: relative;
  top: 0;
  left: 0;
}

img {
  width: 1440px;
  height: 1024px;
  position: relative;
  top: 0;
  left: 0;
}

.svgContainer {
  width: 1440px;
  height: 1024px;
  position: absolute;
  top: 0;
  left: 0;
}

.tooltip {
  height: 100%;
  display: flex;
  align-items: center;
}

.tooltip-favs-accordian {
  display: flex;
  align-items: center;
  color: #3b3b3b;
}
.tooltip-favs {
  display: flex;
  align-items: center;
  /* color: #3b3b3b; */
}

.details-container {
  display: flex;
}

.active {
  fill: #4480ca;
  fill-opacity: 70%;
}

/* #main-stairs:hover {
  fill: #8337cb;
  fill-opacity: 90%;
} */

#customer:hover,
#payment:hover,
#sales:hover,
#digitalExperience:hover,
#cmpStore:hover,
#digitalPerformance:hover,
#cmpWeb:hover,
#retailRange:hover,
#digitalCountries:hover,
#cyberSecurity:hover,
#digitalRoadmap:hover,
#digitalSecurity:hover,
#financial:hover,
#cmpApp:hover,
#ecosystems:hover {
  fill: #3776cb;
  fill-opacity: 90%;
}
#livingRoom:hover {
  fill: #9bc5bb;
  fill-opacity: 100%;
}
#teamArea:hover {
  fill: #ced8cf;
  fill-opacity: 90%;
}
#standUpArea:hover {
  fill: #f7e8cb;
  fill-opacity: 100%;
}
#openCollabThree:hover,
#openCollabOne:hover,
#openCollabTwo:hover {
  fill: #f3c99f;
  fill-opacity: 100%;
}

#focusAreaOne:hover,
#focusAreaTwo:hover,
#focusAreaThree:hover {
  fill: #f8df9c;
  fill-opacity: 100%;
}
#phoneBoothOne:hover,
#phoneBoothTwo:hover,
#phoneBoothThree:hover,
#phoneBoothFour:hover {
  fill: #a4998d;
  fill-opacity: 100%;
}
